import api from '@/plugins/api'
import { GetAllDocumentTypesResponse } from '@/services/documentType/types'

const DocumentTypeService = {
  all(query = ''): Promise<GetAllDocumentTypesResponse> {
    return api().get(`/document-types${query}`)
  },
}

export default DocumentTypeService
