
























































































import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
import TabsSwitcher from '@/components/TabsSwitcher.vue'
import ClientDetails from '@/views/Clients/tabs/ClientDetails.vue'
import UserDetails from '@/views/Clients/tabs/UserDetails.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import Shareholders from '@/views/Clients/tabs/Shareholders.vue'
import Documents from '@/views/Clients/tabs/Documents.vue'
import DocumentTypeService from '@/services/documentType'
import ClientService from '@/services/client'
import type { CreateClientDTO, ResponseClientDTO } from '@/services/client/types'
import type { DocumentTypeDTO } from '@/services/documentType/types'
import { errorMessages, today } from '@/utils/helpers'
import ClientInvoices from '@/views/Invoice/ClientInvoices.vue'
import DeactivateClientForm from '@/views/Invoice/DeactivateClientForm.vue'
import CreditInsuranceList from '@/views/CreditInsurance/CreditInsuranceList.vue'
import CreateCreditInsurance from '@/views/CreditInsurance/CreateCreditInsurance.vue'
import { CreditInsuranceListRef } from '@/services/creditInsurance/types'
import { ValidForm } from '@/interfaces/Refs'
import ClientStatus from '@/views/Clients/partials/ClientStatus.vue'
import UserStatusChip from '@/views/User/partials/UserStatusChip.vue'
import { UserStatus } from '@/views/User/types'
import FiscalizationStatusChip from '@/views/Clients/views/FiscalizationStatusChip.vue'

export default Vue.extend({
  name: 'ClientForm',
  components: {
    UserStatusChip,
    ClientStatus,
    CreateCreditInsurance,
    DeactivateClientForm,
    Documents,
    Shareholders,
    SubmitButton,
    CancelButton,
    UserDetails,
    ClientDetails,
    TabsSwitcher,
    CardTitle,
    ClientInvoices,
    CreditInsuranceList,
    FiscalizationStatusChip,
  },
  props: {
    id: { type: String, default: '' },
    viewType: { type: String, default: 'create' },
  },
  data: function (): {
    creditInsuranceTab: number
    responseData: ResponseClientDTO
    createData: CreateClientDTO
    documentTypes: Array<DocumentTypeDTO>
    valid: boolean
  } {
    return {
      creditInsuranceTab: 4,
      responseData: {
        client: {
          status: '',
          companyName: '',
          email: '',
          alternativeEmail: '',
          address: '',
          nuis: '',
          city: '',
          country: '',
          administrator: '',
          shareholders: [],
          users: [],
          documents: [],
          authorizedPerson: '',
          authorizedPersonPhone: '',
          authorizedPersonEmail: '',
          clientFiscalStatus: '',
        },
      },
      createData: {
        client: {
          companyName: '',
          email: '',
          alternativeEmail: '',
          address: '',
          nuis: '',
          city: '',
          country: '',
          administrator: '',
          shareholders: [],
          authorizedPerson: '',
          authorizedPersonEmail: '',
          authorizedPersonPhone: '',
          clientFiscalStatus: '',
        },
        user: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: 3,
          status: UserStatus.ACTIVATED,
        },
        documents: [],
      },
      valid: false,
      documentTypes: [],
    }
  },
  computed: {
    tabs(): string[] {
      const defaultTabs = [this.$tc('clientDetails'), this.$tc('shareholders'), this.$tc('documents.documents')]
      const onlyEditableTabs = [this.$tc('clientInvoices'), this.$tc('creditInsurance'), this.$tc('status')]
      return this.isEditMode || this.isShowMode ? [...defaultTabs, ...onlyEditableTabs] : defaultTabs
    },
    clientName(): string {
      const firstName = this.createData.user ? this.createData.user.firstName : ''
      const lastName = this.createData.user ? this.createData.user.lastName : ''
      return `${firstName} ${lastName}`
    },
    isShowMode(): boolean {
      return this.viewType === 'show'
    },
    isCreateMode(): boolean {
      return this.viewType === 'create'
    },
    isEditMode(): boolean {
      return this.viewType === 'edit'
    },
    userId(): string {
      return String(this.createData.user.id) || ''
    },
  },
  beforeMount() {
    if (this.isCreateMode || this.isEditMode) this.fetchDocumentTypes()
    if (this.isEditMode || this.isShowMode) this.fetchItem()
  },
  methods: {
    openTab() {
      return this.$refs.tabSwitcher ? (this.$refs.tabSwitcher as Vue).$data.tab : 0
    },
    goToDeactivation(deactivationTab = 5) {
      if (this.$refs.tabSwitcher) {
        const tabSwitcher = this.$refs.tabSwitcher as Vue
        tabSwitcher.$data.tab = deactivationTab
      }
    },
    fetchCreditInsurances() {
      if (this.$refs.creditInsurance) {
        const creditInsurance = this.$refs.creditInsurance as CreditInsuranceListRef
        creditInsurance.paginateAndFetchData()
      }
    },
    async add(): Promise<void> {
      ;(this.$refs.form as ValidForm).validate()
      const dataFault = this.checkSubmittedData()
      if (dataFault !== '') {
        this.$toast.error(errorMessages(dataFault))
        return
      }

      const loader = this.$loading.show()
      try {
        const response = await ClientService.create(this.createData)
        if (response) {
          this.$emit('created')
          this.$toast.success(response.data.message)
          await this.$router.push({ name: 'Client' })
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    async fetchDocumentTypes(): Promise<void> {
      let queryString = '?type=client'
      if (this.isShowMode) {
        queryString += '&withDeleted=true'
      }

      const { data } = await DocumentTypeService.all(queryString)
      this.documentTypes = data.data.items
    },
    async fetchItem(): Promise<void> {
      const loader = this.$loading.show()
      let queryString = ''
      if (this.isShowMode) {
        queryString = '?withDeleted=true'
      }

      const { data } = await ClientService.find(this.id, queryString)
      if (data.data) this.responseData = data.data

      // NOTE: refactor this code block
      if (this.responseData.client) {
        this.createData.user = this.responseData.client.users[0]
        this.createData.documents = this.responseData.client.documents
        this.createData.client.shareholders = this.responseData.client.shareholders
        this.createData.client.companyName = this.responseData.client.companyName
        this.createData.client.email = this.responseData.client.email
        this.createData.client.alternativeEmail = this.responseData.client.alternativeEmail
        this.createData.client.address = this.responseData.client.address
        this.createData.client.nuis = this.responseData.client.nuis
        this.createData.client.status = this.responseData.client.status
        this.createData.client.city = this.responseData.client.city
        this.createData.client.country = this.responseData.client.country
        this.createData.client.administrator = this.responseData.client.administrator
        this.createData.client.authorizedPerson = this.responseData.client.authorizedPerson
        this.createData.client.authorizedPersonEmail = this.responseData.client.authorizedPersonEmail
        this.createData.client.authorizedPersonPhone = this.responseData.client.authorizedPersonPhone
        this.createData.client.clientFiscalStatus = this.responseData.client.clientFiscalStatus
      }

      await this.fetchDocumentTypes()
      loader.hide()
    },
    async update(): Promise<void> {
      const refForm = this.$refs.form as ValidForm
      refForm.validate()
      const dataFault = this.checkSubmittedData()
      if (dataFault !== '') {
        this.$toast.error(errorMessages(dataFault))
        return
      }

      this.responseData = {
        client: {
          companyName: this.createData.client.companyName,
          email: this.createData.client.email,
          alternativeEmail: this.createData.client.alternativeEmail,
          address: this.createData.client.address,
          nuis: this.createData.client.nuis,
          city: this.createData.client.city,
          country: this.createData.client.country,
          administrator: this.createData.client.administrator,
          shareholders: this.createData.client.shareholders,
          users: [this.createData.user],
          documents: this.createData.documents,
          authorizedPerson: this.createData.client.authorizedPerson,
          authorizedPersonPhone: this.createData.client.authorizedPersonPhone,
          authorizedPersonEmail: this.createData.client.authorizedPersonEmail,
          clientFiscalStatus: this.createData.client.clientFiscalStatus,
        },
      }
      const loader = this.$loading.show()
      try {
        const response = await ClientService.update(this.id, this.responseData.client)
        if (response) {
          this.$emit('updated')
          this.$toast.success(response.data.message)
          await this.$router.push({ name: 'Client' })
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    checkSubmittedData(): string {
      const clientNullableKeysForFiscalization = ['status', 'clientFiscalStatus']

      for (const [key, value] of Object.entries(this.createData.client)) {
        if (!value && !clientNullableKeysForFiscalization.includes(key)) {
          return `Please provide: ${key}`
        }
      }

      const tempNullableKeysUntilFiscalization = [
        'isEmailVerified',
        'businessUnitCode',
        'tcrCode',
        'tcrMaintainerCode',
        'operatorCode',
      ]
      for (const [key, value] of Object.entries(this.createData.user)) {
        if (!value && !tempNullableKeysUntilFiscalization.includes(key)) {
          return `Please provide: ${key}`
        }
      }

      if (this.createData.documents.length) {
        for (const document of this.createData.documents) {
          if (document.base64 || document.url) {
            for (const [key, value] of Object.entries(document)) {
              if (!value && !['approvedBy', 'companyName', 'approvedAt'].includes(key)) {
                return `Missing ${key}, please fill documents data`
              }
              if (key === 'expiresAt' && value && value <= today()) {
                return 'Document expiration must be in future'
              }
            }
          } else document.expiresAt = null
        }
      }
      return ''
    },
  },
})
