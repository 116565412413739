






































import { defineComponent } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import { formatDate } from '@/utils/helpers'
import EditCreditInsurance from '@/views/CreditInsurance/EditCreditInsurance.vue'
import CreditInsuranceService from '@/services/creditInsurance'
import ShowCreditInsurance from '@/views/CreditInsurance/ShowCreditInsurance.vue'
import CreateCreditInsurance from '@/views/CreditInsurance/CreateCreditInsurance.vue'

export default defineComponent({
  setup({ id }) {
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      CreditInsuranceService(id),
    )
    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      CreditInsuranceService,
    }
  },
  name: 'CreditInsuranceList',
  props: {
    id: { type: String, default: '' },
    clientName: { type: String, default: '' },
    openTab: { type: Number },
    thisTabIndex: { type: Number },
    viewType: { type: String, default: 'create' },
  },
  components: { CreateCreditInsurance, EditCreditInsurance, ShowCreditInsurance },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'totalAmount', text: this.$t('totalAmount'), sortable: true },
        { value: 'remainingAmount', text: this.$t('remainingAmount'), sortable: true },
        { value: 'expiresAt', text: this.$t('expirationDate'), sortable: true },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
  },
  methods: {
    paginateAndFetchData() {
      this.pagination.value.itemsPerPage = 10
      this.pagination.value.page = 1
      this.fetchData()
    },
  },
  beforeMount() {
    this.paginateAndFetchData()
  },
  watch: {
    openTab: function (tab) {
      if (tab === this.thisTabIndex) this.paginateAndFetchData()
    },
  },
})
