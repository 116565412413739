





import Vue from 'vue'

export default Vue.extend({
  name: 'StatusTextFormatter',
  props: {
    status: { type: String, default: '' },
  },
  methods: {
    statusColor(status: string | undefined) {
      if (status === 'active') return 'green--text'
      else if (status === 'pending') return 'red--text'
      else return 'grey--text'
    },
  },
})
