










import Vue from 'vue'
export default Vue.extend({
  name: 'TabsSwitcher',
  props: {
    tabs: { type: Array, required: true },
  },
  data() {
    return {
      tab: 0,
    }
  },
})
