






import { defineComponent } from '@vue/composition-api'
import UserService from '@/services/user'
import { errorMessages } from '@/utils/helpers'

export default defineComponent({
  name: 'ResendActivationEmailButton',
  props: { id: { type: String, default: '', required: true } },
  methods: {
    async resendActivationEmail(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await UserService.resendActivationEmail(this.id)
        if (response) this.$toast.success(response.data.message)
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
})
