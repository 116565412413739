




















import type { DocumentTypeDTO } from '@/services/documentType/types'
import DocumentUploader from '@/views/Clients/tabs/DocumentUploader.vue'
import { defineComponent } from '@vue/composition-api'
import { ClientDocumentDTO } from '@/services/client/types'

export default defineComponent({
  name: 'Documents',
  components: { DocumentUploader },
  props: {
    documents: {
      type: Array as () => ClientDocumentDTO[],
      default: () => [],
    },
    documentTypes: {
      type: Array as () => DocumentTypeDTO[],
      default: () => [],
    },
    disabled: { type: Boolean, default: false },
    clientId: { type: Number, required: true },
  },
  model: { prop: 'documents' },
  beforeMount() {
    this.syncDocuments()
  },
  methods: {
    getDocumentIndex({ name }: DocumentTypeDTO): number {
      return this.documents?.findIndex(({ type }) => type === name)
    },
    async syncDocuments() {
      await this.documentTypes?.forEach(({ name }) => {
        const foundDocumentPerType = this.documents?.some(({ type }) => type === name)
        if (!foundDocumentPerType)
          this.documents?.push({
            type: name,
            path: '',
            expiresAt: '',
            approvedBy: NaN,
            approvedAt: '',
            url: '',
            base64: '',
          })
      })
      this.documents?.forEach(({ type }, index) => {
        const foundDocumentPerType = this.documentTypes?.some(({ name }) => type === name)
        if (!foundDocumentPerType) this.documents?.splice(index, 1)
      })
    },
  },
})
