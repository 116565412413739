



































































































































import Vue from 'vue'
import type { ClientDTO } from '@/services/client/types'
import { email, ValidationRule } from 'vuelidate/lib/validators'
import CountriesSelect from '@/components/selects/CountriesSelect.vue'
import ClientService from '@/services/client'
import { ClientFiscalStatus, ClientStatus } from '@/views/Clients/types'

export default Vue.extend({
  name: 'ClientDetails',
  components: { CountriesSelect },
  data: function (): {
    rules: { required: (value: string) => string | boolean; email: (value: string) => ValidationRule }
    loading: boolean
    ClientFiscalStatus: any
    ClientStatus: any
  } {
    return {
      rules: {
        required: (value: string) => !!value || this.$tc('validation.required'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        email: (value: string) => email(value) || this.$tc('validation.email'),
      },
      loading: false,
      ClientFiscalStatus,
      ClientStatus,
    }
  },
  props: {
    client: {
      type: Object as () => ClientDTO,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    viewType: { type: String, default: 'create' },
    clientId: { type: Number },
  },
  model: {
    prop: 'client',
  },
  computed: {
    computedClient: {
      get(): ClientDTO {
        return this.client
      },
      set(value: ClientDTO): void {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async retryFiscalization(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await ClientService.retryFiscalization(this.clientId)
        if (response) {
          this.$toast.success(response.data.message)
        }
      } catch (err) {
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        this.$emit('updated')
        loader.hide()
      }
    },
  },
})
