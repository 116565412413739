































































import { defineComponent, reactive } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import PaymentForm from '@/views/Invoice/PaymentForm.vue'
import InvoiceStatus from '@/views/Invoice/components/InvoiceStatus.vue'
import InvoiceService from '@/services/clientInvoice'
import ShowButton from '@/components/buttons/ShowButton.vue'
import InvoicePayments from '@/views/Invoice/InvoicePayments.vue'
import InvoiceForm from '@/views/Invoice/InvoiceForm.vue'

export default defineComponent({
  name: 'ClientInvoices',
  setup({ id }) {
    const searchParams = reactive({ id, type: 'buy' })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      InvoiceService,
      searchParams,
    )
    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      searchParams,
    }
  },
  props: { id: { type: String, default: '' } },
  components: { InvoicePayments, PaymentForm, InvoiceStatus, ShowButton, InvoiceForm },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'procurement.id', text: this.$t('tenderId'), sortable: true },
        { value: 'procurement.name', text: this.$t('tenderTitle'), sortable: true },
        { value: 'procurement.isPublishedAt', text: this.$t('date'), sortable: true },
        { value: 'value', text: this.$t('total'), sortable: true },
        { value: 'paid', text: this.$t('paid'), sortable: true },
        { value: 'remaining', text: this.$t('remaining'), sortable: true },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
  },
  methods: {
    async fetchStatistics() {
      const { data } = await InvoiceService.statistics(this.id, this.searchParams.type)
      this.statistics = data.data
    },
    refresh() {
      this.fetchStatistics()
      this.fetchData()
    },
    fetchInvoices(invoiceType: string) {
      this.searchParams.type = invoiceType
      this.fetchData()
    },
  },
  data() {
    return { selected: [], statistics: { paidAmount: 0, unpaidAmount: 0, sumAmount: 0 } }
  },
  watch: {
    totalRecords: function () {
      this.fetchStatistics()
    },
  },
})
