export enum ClientFiscalStatus {
  NOT_SAVED = 'not_saved',
  PARTLY_SAVED = 'partly_saved',
  SAVED = 'saved',
  NOT_SYNCED = 'not_synced',
  PARTLY_SYNCED = 'partly_synced',
}

export enum ClientStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  INACTIVE = 'inactive',
}
