

































import FileDropzone from '@/components/FileDropzone.vue'
import { ClientDocumentDTO } from '@/services/client/types'
import Vue from 'vue'
import DatePicker from '@/components/DatePicker.vue'
import moment from 'moment'

type Document = {
  base64: string
  documentTypeId: string
  expiresAt: string
  extension: string
}

export default Vue.extend({
  name: 'DocumentUploader',
  components: { DatePicker, FileDropzone },
  props: {
    documentType: {
      type: Object,
      default: () => ({ id: '', name: '' }),
    },
    document: {
      type: Object,
      default: () => ({ base64: '', documentTypeId: '', expiresAt: '', extension: '' }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    documentKey: {
      type: Number,
      default: 1,
    },
    clientId: {
      type: Number,
      required: true,
    },
  },
  model: {
    prop: 'document',
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    computedDocument: {
      get(): Document {
        return this.document
      },
      set(value: ClientDocumentDTO): void {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    twoDaysAfterToday(): string {
      return moment(new Date()).add(2, 'days').format('YYYY-MM-DD')
    },
  },
})
