




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ClientStatus',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    color(): string {
      if (this.status === 'active') return 'success'
      if (this.status === 'pending') return 'warning'
      return 'error'
    },
  },
})
