

















import Vue from 'vue'
import { CountriesDTO } from '@/services/client/types'
import ClientService from '@/services/client'

export default Vue.extend({
  name: 'CountriesSelect',
  props: {
    country: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  model: { prop: 'country' },
  data: function (): {
    rules: { required: (value: string) => string | boolean }
    countries: CountriesDTO[]
    loading: boolean
  } {
    return {
      rules: {
        required: (value: string) => !!value || this.$tc('validation.required'),
      },
      countries: [],
      loading: false,
    }
  },
  async mounted(): Promise<void> {
    this.loading = true
    try {
      const { data } = await ClientService.countries()
      if (data) this.countries = data.data
    } finally {
      this.loading = false
    }
  },
  computed: {
    computedClient: {
      get(): string {
        return this.country
      },
      set(value: number): void {
        this.$emit('input', value)
      },
    },
  },
})
