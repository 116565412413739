



























































import { ClientUserDTO } from '@/services/client/types'
import { defineComponent } from '@vue/composition-api'
import { email } from 'vuelidate/lib/validators'
import ResendActivationEmailButton from '@/components/buttons/ResendActivationEmailButton.vue'
import { UserStatus } from '@/views/User/types'

export default defineComponent({
  name: 'UserDetails',
  components: { ResendActivationEmailButton },
  data() {
    return {
      rules: {
        required: (value: string) => !!value || this.$tc('validation.required'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        email: (value: string) => email(value) || this.$tc('validation.email'),
      },
      UserStatus,
    }
  },
  props: {
    user: {
      type: Object as () => ClientUserDTO,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    id: { type: String, default: '' },
    viewType: { type: String, default: 'create' },
  },
  model: {
    prop: 'user',
  },
  computed: {
    computedUser: {
      get(): ClientUserDTO {
        return this.user
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
    isShowMode(): boolean {
      return this.viewType === 'show'
    },
    isEditMode(): boolean {
      return this.viewType === 'edit'
    },
  },
})
