






























































import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { errorMessages } from '@/utils/helpers'
import { CreateCreditInsuranceDTO, CreditInsuranceStatus } from '@/services/creditInsurance/types'
import CreditInsuranceService from '@/services/creditInsurance'
import DatePicker from '@/components/DatePicker.vue'
import CreateButton from '@/components/buttons/CreateButton.vue'
import StatusTextFormatter from '@/views/CreditInsurance/partials/StatusTextFormatter.vue'
import FileDropzone from '@/components/FileDropzone.vue'
import { today } from '@/utils/helpers'

export default Vue.extend({
  name: 'CreateCreditInsurance',
  components: { FileDropzone, StatusTextFormatter, CreateButton, DatePicker, SubmitButton, CancelButton, CardTitle },
  props: {
    activationButton: { type: String, default: 'default' },
    clientId: { type: String, default: undefined },
    clientName: { type: String, default: undefined },
  },
  data: function (): {
    dialog: boolean
    creditInsurance: CreateCreditInsuranceDTO
    today: () => string
  } {
    return {
      dialog: false,
      today,
      creditInsurance: {
        totalAmount: 0,
        remainingAmount: 0,
        expiresAt: '',
        status: CreditInsuranceStatus.PENDING,
        base64: '',
        extension: '',
      },
    }
  },
  methods: {
    setImage(value: { expiresAt: string; base64: string; extension: string }): void {
      this.creditInsurance.base64 = value.base64
      this.creditInsurance.extension = value.extension
    },
    async add(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await CreditInsuranceService(this.clientId).create(this.creditInsurance)
        if (response) {
          this.$emit('created')
          this.$toast.success(response.data.message)
          this.close()
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    close() {
      this.dialog = false
      this.creditInsurance.expiresAt = ''
      this.creditInsurance.remainingAmount = 0
      this.creditInsurance.totalAmount = 0
      this.creditInsurance.status = CreditInsuranceStatus.PENDING
      this.creditInsurance.base64 = ''
      this.creditInsurance.extension = ''
    },
  },
})
