




























import Vue from 'vue'
import type { ShareholderDTO } from '@/services/client/types'
import ClearButton from '@/components/buttons/ClearButton.vue'
import CreateButton from '@/components/buttons/CreateButton.vue'

export default Vue.extend({
  name: 'Shareholders',
  components: { CreateButton, ClearButton },
  props: {
    shareholders: {
      type: Array as () => ShareholderDTO[],
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'shareholders',
  },
  computed: {
    computedShareholders: {
      get(): ShareholderDTO[] {
        return this.shareholders
      },
      set(value: ShareholderDTO[]): void {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    add(): void {
      const newShareholder = {
        firstName: '',
        lastName: '',
        citizenship: '',
      }
      this.computedShareholders.push(newShareholder)
    },
    remove(index: number): void {
      this.computedShareholders.splice(index, 1)
    },
  },
})
