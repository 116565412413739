




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FiscalizationStatusChip',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    color(): string {
      if (this.status === 'not_saved') return 'red'
      if (this.status === 'saved') return 'green'

      return 'orange'
    },
  },
})
